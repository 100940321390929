<template>

    <div class="w-full h-full px-3 py-4">
        
        <div class="h-auto w-full rounded-lg shadow_box flex flex-col p-2 mb-6" :class="'bg-module-'+mode">
            
            <!-- <div class="h-12 flex flex-row px-2">
                <div class="h-full w-10/12 flex flex-row">
                    <div class="h-full w-full flex flex-col justify-center items-start">
                        <span class="text-2xl font-semibold" :class="'text-dfont-'+mode">{{ $t('filters') }}</span>
                    </div>
                </div>
                <div class="h-full w-2/12 flex flex-col justify-start items-end"></div>
            </div> -->

            <div class="h-auto w-full px-2 mt-6 flex flex-col justify-center items-start">
                <span class="text-md font-medium mb-2" :class="'text-dfont-'+mode" >{{$t('period')}}</span>
                <div class="h-8 w-full">
                    <div class="h-full rounded-lg flex flex-row justify-between items-center" :class="'bg-box-'+mode">
                        <!-- <el-select v-model="period" placeholder="Select" class="w-full">
                            <el-option
                            v-for="item in periods"
                            :key="item.value"
                            :label="translate(item.name)"
                            :value="item.value"
                            class="w-full">
                            </el-option>
                        </el-select> -->
                        <select name="period" v-model="period" class="h-full w-full rounded-lg px-2" :class="'bg-box-'+mode+' text-dfont-'+mode">
                            <option v-for="(el,index) in periods" :key="index" :value="el.value" :class="'text-dfont-'+mode">{{translate(el.name)}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <filter-months v-if="period == 'month'"   :mode='mode'/>
            <div class="h-10 mt-12 px-12">
                <div class="h-full rounded-lg bg-red flex flex-row justify-center items-center cursor-pointer"
                    @click="applyFilters()">
                    <span class="text-lg text-white">{{$t('apply')}}</span>
                </div>
            </div>

            <div class="h-6"></div>

        </div>

    </div>

</template>

<script>
import { All } from '@/api/components';
import { state, actions } from '@/store';

export default {
    components:{
        All
    },
    data(){
        return{
            periods: [
                { 
                    name: 'Mes',
                    value: 'month'
                },
                { 
                    name: 'Trimestre',
                    value: 'quarter'
                },
                { 
                    name: 'Año',
                    value: 'year'
                }
            ],
            period: '',
            zoneFilter: null,
            representantFilter: null,
            companyfilter: null,
            representants: null,
            companies:null
        }
    },
    methods:{
        translate(word) {
            switch (word) {
                case 'Mes':
                    return this.$t("monthly")
                    break;
                case 'Trimestre':
                    return this.$t("quarterly")
                    break;
                case 'Año':
                    return this.$t("year")
                    break;
            }
        },
        applyFilters() {
            actions.setPeriod(this.period)
            if (state.user.rol.Name === 'Representante') {
                actions.setIdEmployeeSelected(this.representantFilter.toLowerCase())
                if (this.companyfilter) { actions.setIdCompany(this.companyfilter.toLowerCase()) }
                else { actions.setIdCompany(this.companyfilter) }
                let arrayResult = Object.values(this.representants)
                for (let index = 0; index < arrayResult.length; index++) {
                    if (arrayResult[index].Id === this.representantFilter) { actions.setEmployeeName(arrayResult[index].Name) }
                }
            }
            this.$router.push( { name: 'client' } );
        }
    },
    computed: {
        user() { return state.user },
        queryrelations() { return { id: state.user.id } },
        mode(){
            return state.mode
        }
    },
    watch: {
        representantFilter(n, o) {
            if (o != null) { this.companyfilter = null }
            setTimeout(() => {
                for (let index = 0; index < Object.values(this.representants).length; index++) {
                    if (n === Object.values(this.representants)[index].Id) {
                        this.companies = Object.values(this.representants)[index].EmployeeCompanies
                    }
                }
            }, 1000);
        }
    },
    mounted() {
        this.period = state.period
        this.representantFilter = state.idEmployeeSelected.toUpperCase()
        this.companyfilter = state.idCompany.toUpperCase()
    }
}
</script>

<style>
    /* .el-input__inner {
        background-color: rgba(0, 0, 0, 0) !important;
        border: 0;
        color: #2C5358;
    } */
</style>